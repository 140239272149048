import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "o auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "40px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    fontSize: "1.8rem",
    color: "#fff",
    background: "tomato",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase",
  },
  subHeading: {
    color: "#fff",
    padding: 0,
    textTransform: "uppercase",
  },
  body1: {
    color: "tomato",
  },
  subtitle1: {
    color: "tan",
  },
}));

const resumeData = [
  {
    heading: "Bank of America",
    subHeading: "NodeJS, Jenkins, Vue, HTML/CSS",
    description: `Feature Lead for an Agile team developing NodeJS based build tools used for Bank of America and Merrill Lynch web pages. Tools include a static site generator, pre-render system for SEO optimization, Lighthouse integration for performance metrics, component scaffolding system, and a UI component library.`
  },
  {
    heading: "Bank of America",
    subHeading: "Javascript, Vue, HTML/CSS",
    description: `Lead UI Developer for an Agile team working on BankOfAmerica.com sales and authenticated pages. Primary responsibilties included reviewing incoming work for standards and ADA compliance, mentoring junior developers, and creating implementation plans.`
  },
  {
    heading: "Methode Electronics",
    subHeading: "Ruby, C++, Javascript, HTML/CSS",
    description: `Lead developer and Product Designer for Methode's Data Center Infrastructure Management product line. I helped create a full suite of hardware and software for managing data center assets, environmental readings, physical security, and power distribution at the rack level.`
  },
  {
    heading: "Rampid Interactive",
    subHeading: "PHP, HTML/CSS",
    description: `Customer Support Lead for Outwar.com, a browser based MMORPG. My primary responsibilties included managing the support team, handling customer issues, and maintaining the backend support systems. On occation I would also create new game content and fix bugs.`
  }
]

const Resume = () => {
  const classes = useStyles();

  const lineItem = (resumeItem) => (
    <Box component="div" className={classes.timeLineItem}>
      <Typography
        variant="h5"
        align="center"
        className={classes.subHeading}
      >
        {resumeItem.heading}
      </Typography>
      <Typography variant="body1" align="center" className={classes.body1}>
        {resumeItem.subHeading}
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.subtitle1}
      >
        {resumeItem.description}
      </Typography>
    </Box>
  );

  return (
    <Box component="header" className={classes.mainContainer}>
      <Typography variant="h4" align="center" className={classes.heading}>
        Working Experience
      </Typography>
      <Box component="div" className={classes.timeLine}>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          {(new Date().getFullYear())}
        </Typography>
        {lineItem(resumeData[0])}

        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2022
        </Typography>
        {lineItem(resumeData[1])}

        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2017
        </Typography>
        {lineItem(resumeData[2])}

        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2011
        </Typography>
        {lineItem(resumeData[3])}
      </Box>
    </Box>
  );
};

export default Resume;
